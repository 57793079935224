import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'

export default {
  namespaced: true,

  state: {},

  getters: {},

  actions: {
    async checkName({ commit, dispatch }, params) {
      const loadingName = 'checkName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Zone/check/name', { params: {name: params.Text} })
        },
        success: result => result
      })
      return results
    },
    async searchZone({ commit, dispatch }, params) {
      const loadingName = 'searchZone'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Zone/filter', { params: {name: params.Text} })
        },
        success: result => result
      })
      return results
    },
    async postNewZone({ commit, dispatch }, payload) {
      const loadingName = 'postNewZone'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Zone', payload)
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {}
}
